import React, { Fragment } from 'react';

const Privacy = () => {
    return (
        <div>
            <h1>Privacy Policy</h1><p>At Little Ratoons, we respect your privacy and are committed to protecting your personal information. This privacy policy statement explains how we collect, use, and share your personal data when you use our e-commerce app.</p>

            <h2>1. Information We Collect</h2>
            <p>We collect personal information when you register for our app, place an order, or interact with our vendors. This may include your name, email address, phone number, shipping and billing address, payment information, and browsing and purchasing history. We also collect data from your device and location when you use our app.</p>

            <h2>2. How We Use Your Information</h2>
            <p>We use your information to provide and improve our services, process your orders, communicate with you about your account and our vendors, personalize your experience, and prevent fraud. We may also use your information for marketing and promotional purposes, unless you opt out.</p>

            <h2>3. How We Share Your Information</h2>
            <p>We share your information with our vendors to fulfill your orders, with third-party service providers who help us operate our app and provide our services, and with law enforcement or other parties in response to legal requests. We may also share anonymized or aggregated data with our partners for analytical purposes.</p>

            <h2>4. Your Rights</h2>
            <p>You have the right to access and control your personal information, including the right to correct, delete, or restrict our use of your data. You can also opt out of marketing communications and revoke your consent to our use of your data at any time. However, please note that certain data is necessary for us to provide our services and fulfill your orders.</p>

            <h2>5. Data Security</h2>
            <p>We take data security seriously and use appropriate measures to protect your personal information from unauthorized access, disclosure, or modification.</p>

            <h2>6. Changes to Our Privacy Policy</h2>
            <p>We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes and obtain your consent where required.</p>

            <p>If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:care@ratooapp.com">care@ratooapp.com</a>.</p>
        </div>
    )
};
export default Privacy;